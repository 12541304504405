import React from "react";
import { Link } from "gatsby";

import { Typography } from "../../common/Typography/Typography";

import styles from "./Home.module.scss";
import BlockText from "../../content/block-text";

const Home = ({ title, text }) => {
  return (
    <section className={styles.intro}>
      <div className={styles.introInner}>
        <Typography type="mega" tagName="h1" className={styles.introTitle}>
          {title}
        </Typography>
        <Typography tagName="section" type="heading" className={styles.introMain}>
          <BlockText blocks={text || []} />
        </Typography>
      </div>
    </section>
  );
};

export { Home };
