import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/content/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { Home } from "../components/pages/Home/Home";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      name
      title
      _rawHome
    }
  }
`;

const IndexPage = ({ data, errors, location }) => {
  if (errors) {
    return (
      <Layout location={location}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout location={location}>
      <SEO title={site.name} description={site.title} />

      <Home title={site.title} text={site._rawHome} />
    </Layout>
  );
};

export default IndexPage;
